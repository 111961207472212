@import '~app/mixins';

$color-border: #d6d6d6;

.property {
  .title {
    color: $color-teal;
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
    margin: 0;

    @include text-overflow;
  }

  .subject {
    padding: 25px;
    background: $color-white;

    .widgetInfo {
      display: flex;
      margin-top: 15px;

      .imagePreview {
        width: 220px;
        min-height: 160px;

        img {
          display: block;
          max-width: 100%;
          height: auto;
        }
      }

      .right {
        padding-left: 15px;

        .spacedValue {
          margin-top: 10px;
        }

        .rowInfo {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          line-height: 26px;

          .label,
          .value {
            font-size: 12px;
            line-height: 18px;
          }

          .label {
            width: 100px;
            color: $color-teal;
          }

          .value {
            max-width: 150px;
            color: $color-grey-1;

            @include text-overflow;
          }
        }

        .bottom {
          margin: 20px 0;
          display: flex;

          .item {
            padding: 0 15px;
            border-left: 2px solid $color-border;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $color-grey;
            text-align: center;

            &:first-child {
              border-left: none;
            }

            .value {
              font-weight: 500;
              width: 100%;
              line-height: 22px;
            }

            .label {
              font-size: 12px;
            }
          }
        }
      }
    }

    .tiles {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px;

      .tile {
        background: $color-dirty-white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 150px;
        height: 80px;
        border-radius: 6px;

        .value {
          font-size: 16px;
          font-weight: bold;
          color: $color-green;
          margin-bottom: 5px;
        }

        .title {
          font-size: 12px;
          color: $color-grey-1;
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .wideButton {
        width: 115px;
      }
      .tealButton {
        color: $color-teal;
        border-color: $color-teal;
      }
    }
  }

  .divider {
    background: $color-border;
    border: none;
    margin: 0;
    width: 100%;
    height: 1px;
  }

  .wrapTitle {
    padding: 15px 25px;
  }

  .scrollTable {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 645px);

    :global {
      .react-tabs {
        background: $color-dirty-white;

        .react-tabs__tab-list {
          display: flex;
          justify-content: space-between;
          padding: 10px 25px 0;
          margin-top: 0;
        }

        .react-tabs__tab-list li.react-tabs__tab {
          padding-bottom: 10px;
          margin: 0;
        }
        .react-tabs__tab-list li.react-tabs__tab:hover, .react-tabs__tab-list li.react-tabs__tab[aria-selected=true] {
          color: $color-teal;
        }
      }
    }
  }
}
